import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import SwiperCore from 'swiper';
import { Autoplay, A11y } from 'swiper/modules';
import { SwiperSlide, Swiper } from 'swiper/react';

import styles from './HomepageCarousel.css';
import { MiscUtils } from '../../../utils/MiscUtils';
import { ArkCssBreakpoints } from '../../constants/ArkCssBreakpoints';
import { HOMEPAGE_CAROUSEL_DELAY } from '../../constants/HomepageCarousel';
import { Button } from '../../FigmaStyleguide/Button/Button';
import { PauseIcon } from '../../FigmaStyleguide/Icons/PauseIcon';
import { PlayIcon } from '../../FigmaStyleguide/Icons/PlayIcon';
import { Analytics } from '../../services/Analytics/Analytics';
import { LeanplumAnalytics, LEANPLUM_EVENTS } from '../../services/Analytics/LeanplumAnalytics';
import { LeanplumCarouselSlide } from '../../store/ducks/leanplum/homepageCarousel';

export const HomepageCarousel = () => {
    const items = useSelector((state) => state.leanplumCarouselData);
    const [sliderPlaying, setSliderPlaying] = useState(true);
    const [activeIndex, setActiveIndex] = useState(0);
    const swiperRef = useRef<SwiperCore>();
    // Device state with initial value
    const [device, setDevice] = useState(() => {
        if (MiscUtils.isServer) {
            return 'desktop';
        }

        const isMobile = window.matchMedia(`(max-width: ${ArkCssBreakpoints.ARK_SMALL_MOBILE}px)`).matches;

        return isMobile ? 'mobile' : window.matchMedia(`(max-width: ${ArkCssBreakpoints.ARK_EXTRA_LARGE_MOBILE}px)`).matches ? 'tablet' : 'desktop';
    });
    const getBackgroundImage = useCallback(
        (item: LeanplumCarouselSlide) => {
            let bg = item.desktopBackgroundImage || item.backgroundImage;

            switch (device) {
                case 'mobile':
                    bg =
                        item.mobileBackgroundImage ||
                        item.tabletBackgroundImage ||
                        item.desktopBackgroundImage ||
                        item.backgroundImage;
                    break;
                case 'tablet':
                    bg = item.tabletBackgroundImage || item.desktopBackgroundImage || item.backgroundImage;
                    break;
                case 'desktop':
                default:
                    break;
            }

            return bg ? `url(${bg})` : undefined;
        },
        [device]
    );
    // Memoize the items' background images to avoid recomputing
    const helmetLinks = useMemo(
        () =>
            items.map((item) => {
                const bgImage = getBackgroundImage(item);

                return (
                    <link
                        key={item.title}
                        rel="preload"
                        as="image"
                        href={bgImage ? bgImage.replace('url(', '').replace(')', '') : ''}
                    />
                );
            }),
        [items]
    );
    const getSlideContent = useCallback(
        (item: LeanplumCarouselSlide, field: string) => {
            return device === 'mobile'
                ? getTextSlide(item, field, 'mobile')
                : getTextSlide(item, field, 'desktop');
        },
        [device]
    );
    const getTextSlide = (item: LeanplumCarouselSlide, field: string, device: string) => {
        switch (field) {
            case 'title':
                return device === 'mobile' && item?.mobileContent ? item.mobileTitle : item.title;
            case 'subtitle':
                return device === 'mobile' && item?.mobileContent ? item.mobileSubtitle : item.subtitle;
            default:
                return '';
        }
    };
    const pauseHandler = () => {
        swiperRef.current.autoplay.running ? swiperRef.current.autoplay.stop() : swiperRef.current.autoplay.start();
        setSliderPlaying(swiperRef.current.autoplay.running);
    };
    const onSlideButtonClick = (item: LeanplumCarouselSlide, i: number) => {
        Analytics.trackEvent(Analytics.general.carouselButton(i.toString(), item.buttonText));
        LeanplumAnalytics.trackEvent(LEANPLUM_EVENTS.CAROUSEL_BUTTON, { slot: i.toString(), buttonLabel: item.buttonText });
        setTimeout(() => (window.location.href = item.buttonLink), 300);
    };

    // Handle device resizing and debounce it
    useEffect(() => {
        const updateDevice = () => {
            const isMobile = window.matchMedia(`(max-width: ${ArkCssBreakpoints.ARK_SMALL_MOBILE}px)`).matches;
            const newDevice = isMobile ? 'mobile' : window.matchMedia(`(max-width: ${ArkCssBreakpoints.ARK_EXTRA_LARGE_MOBILE}}px)`).matches ? 'tablet' : 'desktop';

            setDevice(newDevice);
        };
        const debounceEvent = debounce(updateDevice, 200);

        window.addEventListener('resize', debounceEvent);

        return () => window.removeEventListener('resize', debounceEvent);
    }, []);

    // Memoize the rendered slides to prevent unnecessary re-renders
    const renderedSlides = useMemo(
        () =>
            items.map((item, i) => (
                <SwiperSlide key={'swiper-slide-' + i}>
                    <div
                        className={styles.slide}
                        style={{
                            backgroundImage: getBackgroundImage(item),
                        }}
                    >
                        <div
                            className={classNames(styles.content, {
                                [styles.mobile]:
                                device === 'mobile' &&
                                item?.mobileTitle === '' &&
                                item?.mobileSubtitle === '',
                            })}
                        >
                            <h1>{getSlideContent(item, 'title')}</h1>
                            <h2>{getSlideContent(item, 'subtitle')}</h2>
                            <Button onClick={() => onSlideButtonClick(item, i)} className={styles.whiteBtn}>
                                {item.buttonText}
                            </Button>
                        </div>
                    </div>
                </SwiperSlide>
            )),
        [items, getBackgroundImage, getSlideContent, device]
    );
    // Memoize the pagination to prevent unnecessary re-renders
    const paginationButtons = useMemo(
        () =>
            items.map((_, i) => (
                <button
                    key={'pagination-item-' + i}
                    type="button"
                    className={classNames(styles.pageButton, {
                        [styles.active]: i === activeIndex,
                    })}
                    onClick={() => {
                        if (i !== activeIndex) {
                            swiperRef.current.slideTo(i);
                        }
                    }}
                    aria-label={`Go to slide number ${i + 1}`}
                />
            )),
        [items, activeIndex]
    );

    return (
        <>
            <Helmet>{helmetLinks}</Helmet>
            <Swiper
                onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                }}
                slidesPerView={1}
                onActiveIndexChange={(swiper) => {
                    setActiveIndex(swiper.activeIndex);
                }}
                modules={[Autoplay, A11y]}
                autoplay={{ delay: HOMEPAGE_CAROUSEL_DELAY, disableOnInteraction: true }}
                a11y={{ enabled: true }}
                className={classNames(styles.slide)}
                onAutoplayStart={() => setSliderPlaying(true)}
                onAutoplayStop={() => setSliderPlaying(false)}
            >
                {renderedSlides}
                <div className={styles.pagination}>
                    <button
                        type="button"
                        onClick={pauseHandler}
                        className={styles.handlerBtn}
                        aria-label={sliderPlaying ? 'Pause slider' : 'Autoplay slider'}
                    >
                        {sliderPlaying ? <PauseIcon /> : <PlayIcon />}
                    </button>
                    {paginationButtons}
                </div>
            </Swiper>
        </>
    );
};
